































































































import Vue from 'vue';
import { Depot } from '@/interfaces/depot/depot';
import { DepotRepository } from '@/repositories/depotRepository';
import { Dialog } from '@/interfaces/presentation/dialog';
import { isHigherLevelAdmin } from '@/helpers/userAccountHelper';
import { UserAccount } from '@/interfaces/useraccount/userAccount';
import { SparRepository } from '@/repositories/sparRepository';
import TextModalComponent from '@/components/TextModalComponent.vue';
import { ContactInformation } from '@/interfaces/contactInformation';

export default Vue.extend({
  components: {
    TextModalComponent,
  },
  props: {
    depot: Object as () => Depot,
    height: {
      type: String,
      default: "120px"
    },
    color: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      loadingContactInformation: false,
      showModal: false,
      contactInformation: null as ContactInformation | null,
      editingDepotName: false,
      newDepotName: "",
      depotRepository: new DepotRepository(this),
      sparRepository: new SparRepository(this),
    }
  },
  computed: {
    validName(): boolean {
      return !!this.newDepotName && this.newDepotName.trim().length > 0 && this.newDepotName !== this.depot.name
    },
    stateUser(): UserAccount {
      return this.$store.state.userSession.userAccount
    },
    isAllowedToOpen(): boolean {
      return this.depot && this.depot.adminRoleRequired ? isHigherLevelAdmin(this.stateUser) : true;
    },
    formattedContactInformation(): string {
      if (!this.contactInformation) {
        return ""
      }
      let text = `${this.contactInformation.firstName} ${this.contactInformation.lastName}\n`
      text += `${this.contactInformation.streetName} ${this.contactInformation.streetNumber}`
      text +=  this.contactInformation.apartment ? ` lgh ${this.contactInformation.apartment}\n` : `\n`
      text += `${this.contactInformation.postcode} ${this.contactInformation.city}\n`
      if (this.contactInformation.phone) {
        text += `Tel: ${this.contactInformation.phone}\n`
      }

      return text
    }
  },
  mounted() {
    this.newDepotName = this.depot.name
  },
  methods: {
    confirmEditDepotName() {
      if (!this.validName) {
        return;
      }
      let dialog: Dialog = {
        active: true,
        actionBtnText: "Spara",
        title: "Bekräfta namnändring",
        text: "Är du säker på att du vill ändra namnet på depån till  " + this.newDepotName + "?",
        actionClick: () => {
          this.editDepotName()
        },
        closeBtnText: "Stäng"
      }
      this.$store.commit('setDialog', dialog)
    },
    async editDepotName() {
      let updateResult = await this.depotRepository.updateDepotName(this.depot.id, this.newDepotName);
      if (updateResult) {
        this.depot.name = this.newDepotName;
        this.editingDepotName = false;
      }
    },
    async getContactInformation() {
      if (this.contactInformation == null) {
        this.loadingContactInformation = true
        try {
          this.contactInformation = await this.sparRepository.getContactInformation(this.depot.socialSecurityNumber)
          this.showModal = true
        } finally {
          this.loadingContactInformation = false;
        }
      } else {
        this.showModal = true
      }
    },
    copyContactInformation() {
      let csvArray = [
        this.contactInformation.firstName,
        this.contactInformation.lastName,
        this.contactInformation.streetName,
        this.contactInformation.streetNumber,
        (this.contactInformation.apartment ?? ""),
        this.contactInformation.postcode,
        this.contactInformation.city,
        this.contactInformation.phone,
      ]
      navigator.clipboard.writeText(csvArray.join(";"))
      .then(() => console.log("written!"))
      .catch((reason) => console.log(reason))
    },
  },
});
