












































import Vue from 'vue';
import DocumentStatisticsSummaryTable from '@/components/statistics/DocumentStatisticsSummaryTable.vue';
import { isHigherLevelAdmin } from '@/helpers/userAccountHelper';
import { Business } from '@/interfaces/business/business';
import { ENDPOINTS, get } from '@/api/mainApi';
import { UserAccount } from '@/interfaces/useraccount/userAccount';
import { SelectItem } from '@/interfaces/Vuetify';
import { StatisticsRepository } from '@/repositories/statisticsRepository';

export default Vue.extend({
    components: {
        DocumentStatisticsSummaryTable,
    },
    props: {
        useOrg: Boolean,
    },
    data() {
        return {
            loadingBusinesses: false,
            businessOptions: [] as Array<SelectItem>,
            selectedBusinessId: null,
            statisticsRepository: new StatisticsRepository(),
            totals: {
                numberOfUsersBusiness: 0,
                numberOfDepotsBusiness: 0,
                numberOfUsersOrganization: 0,
                numberOfDepotsOrganization: 0,
            },
        }
    },
    computed: {
        getOrganizationName(): string {
            return this.$store.state.userSession.userAccount.business.organization.name ?? "Organisation" 
        },
        getOrganizationId(): number {
            return this.$store.state.userSession.userAccount.business.organization.id
        },
        getBusinessName(): string {
            return this.$store.state.userSession.userAccount.office.business.name ?? "Företag";
        },
        user(): UserAccount {
            return this.$store.state.userSession.userAccount;
        },
    },
    created() {
        if (this.isHigherLevelAdmin(this.$store.state.userSession.userAccount) && !this.useOrg) { // Need to select a business
            this.getSelectionItems()
        } else { // use values from user
            this.selectedBusinessId = this.getBusinessId()
            if (!this.useOrg) {
                this.loadTotalsForBusiness(this.getBusinessId())
            } else {
                this.loadTotalsForOrganization(this.$store.state.userSession.userAccount.business.organization.id)
            }
        }

    },
    methods: {
        async getSelectionItems() {
            this.loadingBusinesses = true;
            let businesses: Array<Business> = await get(`${ENDPOINTS.ORGANIZATIONS}/${this.getOrganizationId}/${ENDPOINTS.BUSINESSES}`);
            let options: SelectItem[] = []
            for (const business of businesses) {
                options.push({
                    text: business.name,
                    value: business.id,
                })
            }
            this.businessOptions = options;
            this.loadingBusinesses = false;
        },
        getBusinessId(): number {
            return this.$store.state.userSession.userAccount.business.id
        },
        setBusinessId(businessId: number) {
            this.selectedBusinessId = businessId
            this.loadTotalsForBusiness(businessId)
        },
        async loadTotalsForBusiness(businessId: number) {
            let [userCount, depotCount]: number[] = await Promise.all([
            this.statisticsRepository.getUserCountForBusiness(businessId, true),
            this.statisticsRepository.getDepotCountForBusiness(businessId, true),
            ])
            this.totals.numberOfUsersBusiness = userCount;
            this.totals.numberOfDepotsBusiness = depotCount;
        },
        async loadTotalsForOrganization(orgId: number) {
            let [userCount, depotCount]: number[] = await Promise.all([
                this.statisticsRepository.getUserCountForOrganization(orgId, true),
                this.statisticsRepository.getDepotCountForOrganization(orgId, true),
            ])
            this.totals.numberOfUsersOrganization = userCount;
            this.totals.numberOfDepotsOrganization = depotCount;
        },
        isHigherLevelAdmin,
    },
})
