import axios, { AxiosError } from 'axios';

export const BASE_URL = process.env.VUE_APP_API_BASE_URL || 'https://dokumentbevakning.livsarkivet.se';
export const MainApi = 'api';
export const SERVICE_API = 'service';

export const HEADERS = {
  'Authorization': 'Authorization',
  'ContentType': 'Content-Type',
  'ApplicationJson': 'application/json',
};

export const ENDPOINTS = {
  AGREEMENTS: 'agreements',
  API_KEY: 'apikey',
  APPROVE: 'approve',
  ATTACHED_FILE: 'attachedfile',
  AUTH: 'auth',
  BANK_ID: 'bankid',
  BISNODE: 'bisnode',
  BUSINESS: 'business',
  BUSINESSES: 'businesses',
  CAUSES: 'causes',
  CURRENT: 'current',
  CONNECTED: 'connected',
  COOKIES: 'cookies',
  CONTACTINFORMATION: 'contactInformation',
  DECEASED: 'deceased',
  DEFAULT: 'default',
  DEPOT: 'depot',
  DEPOTS: 'depots',
  DISABLE: 'disable',
  DOCUMENT: 'document',
  DOCUMENTS: 'documents',
  EMAIL: 'email',
  EMAILED: 'emailed',
  EMAIL_MESSAGES: 'emailmessages',
  ENABLE: 'enable',
  ENTITY: 'entity',
  EVENT_LOGS: 'eventlogs',
  EXTERNAL_AGENTS: 'externalagents',
  EXTRADITE: 'extradite',
  FRONTPAGE: 'frontpage',
  FUNCTIONS: 'functions',
  GENERATE: 'generate',
  GRANDID: 'grandid',
  IMAGES: 'images',
  INVITE: 'invite',
  IS_VALID: 'isvalid',
  KEEPSAKES: 'keepsakes',
  KEEPSAKE_TYPES: 'keepsaketypes',
  LIFE_ARCHIVE: 'lifearchive',
  LIST: 'list',
  LOGIN: 'login',
  MERGER_RECEIPTS: 'merger-receipts',
  MONITORS: 'monitors',
  MOVE: 'move',
  NAME: 'name',
  NOTIFICATION_SETTINGS: 'notificationsettings',
  OFFICES: 'offices',
  OPENED: 'opened',
  ORGANIZATIONS: 'organizations',
  ORGANIZATION_SETTINGS: 'organizationsettings',
  PENDING: 'pending',
  PERSONAL_DATA_AGREEMENTS: 'personaldataagreements',
  REGISTRATION_PAGE: 'registrationpage',
  REJECT: 'reject',
  SEARCH: 'search',
  SEND: 'send',
  SESSIONS: 'sessions',
  SESSIONS_COOKIE_USER: 'sessions/cookie/user',
  SETTINGS: 'settings',
  SIGN: 'sign',
  SIGNED: 'signed',
  SOCIAL_SECURITY_NUMBER: 'socialsecuritynumber',
  SPAR: 'spar',
  STATISTICS: 'statistics',
  STATUS: 'status',
  STORAGE_PLACES: 'storageplaces',
  SYSTEM: 'system',
  SWITCH: 'switch',
  TOKEN: 'token',
  TOS: 'tos',
  TOS_STATUS: 'tos/status',
  TOTAL: 'total',
  TYPE: 'type',
  TEST_DATA: 'testdata',
  UNCLAIMED: 'unclaimed',
  UNDO: 'undo',
  UPLOAD: 'upload',
  URL: 'url',
  USER_ACCOUNT: 'useraccount',
  USER_ACCOUNTS: 'useraccounts',
};

export const LOGIN_PAGE_URL = BASE_URL + '/inloggning';


/**
 * Generic get request. Normally this should be preferred to more specific requests.
 * @param endpoint
 * @param params
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export async function get(endpoint: string, params?: object) {

  return (
    await axios.get(BASE_URL + '/' + MainApi + '/' + endpoint, {
      withCredentials: true,
      params,
      headers: HEADERS,
    })
      .then(response => response.data)
      .catch((error) => {
        handleError(error);
      })
  );
}

/**
 * Generic get request for services. Normally this should be preferred to more specific requests.
 * @param endpoint
 * @param params
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export async function getService(endpoint: string, params?: object) {

  return (
    await axios.get(BASE_URL + '/' + SERVICE_API + '/' + endpoint, {
      withCredentials: true,
      params,
      headers: HEADERS,
    })
      // No need to store more data then the name and the id
      .then(response => response.data)
      .catch((error) => {
        handleError(error);
      })
  );
}

/*
* Generic delete request. Normally this should be preferred to more specific requests.
* @param endpoint
* @param params
* @returns {Promise<AxiosResponse<T> | never>}
*/
export async function deleteRequest(endpoint: string, params?: object) {
  return (
    await axios
      .delete(BASE_URL + '/' + MainApi + '/' + endpoint, {
        withCredentials: true,
        params,
      })
      .then(response => response.data)
      .catch((error) => {
        handleError(error);
      })
  );
}

/**
 *
 * @param endpoint
 * @param postData
 * @param params
 * @returns {Promise<any>}
 */
export async function post(endpoint: string, postData?: object, params?: object) {
  return (
    await axios
      .post(BASE_URL + '/' + MainApi + '/' + endpoint, postData, {
        withCredentials: true,
        params,
      })
      .then(response => response.data)
      .catch((error) => handleError(error))
  );
}

/**
 *
 * @param endpoint
 * @param postData
 * @param params
 * @returns {Promise<any>}
 */
export async function postFile(endpoint: string, postData?: object, params?: object) {
  return (
    await axios
      .post(BASE_URL + '/' + MainApi + '/' + endpoint, postData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params,
      })
      .then(response => response.data)
      .catch((error) => {
        handleError(error);
      })
  );
}

/**
 *
 * @param endpoint
 * @param params
 * @returns {Promise<any>}
 */
export async function put(endpoint: string, params: object) {
  return (
    await axios
      .put(BASE_URL + '/' + MainApi + '/' + endpoint, params, {
        withCredentials: true,
      })
      .then(response => response.data)
      .catch((error) => {
        handleError(error);
      })
  );
}

function handleError(error: AxiosError) {
  if (error.response?.status === 401) {
    location.href = LOGIN_PAGE_URL;
  }

  console.log(error);

  if (error.response) {
    console.log(error.response);
    throw error.response;
  } else {
    throw error;
  }
}
